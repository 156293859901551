.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  padding: 14px;
}

.showtime-preview > div:first-child {
  font-size: 12px;
  white-space: normal;
}

.showtime-preview > div:not(:first-child) {
  font-size: 11px;
  white-space: normal;
}

.movie-tooltip .movie-info {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
  text-align: left;
}

.movie-tooltip img {
  height: 80px;
  margin-bottom: 10px;
}

.movie-tooltip .movie-title {
  font-size: 1.5em;
  line-height: 40px;
}

.long-title h3 {
  font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
